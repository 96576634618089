import React from 'react';
import { Users } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { useOrganizationStore } from '../../store/useOrganizationStore';
import { Organization } from '../../types';

interface OrganizationSettingsProps {
  organization?: Organization;
}

export function OrganizationSettings({ organization }: OrganizationSettingsProps) {
  const currentOrg = useAuthStore((state) => state.currentOrganization);
  const currentUser = useAuthStore((state) => state.currentUser);
  const updateOrganization = useOrganizationStore((state) => state.updateOrganization);

  const org = organization || currentOrg;
  if (!org) return null;

  const isOwner = currentUser?.organizations[org.id]?.role === 'owner';
  const isAdmin = location.pathname.startsWith('/admin/organizations/');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!org) return;
    
    const formData = new FormData(e.currentTarget);
    await updateOrganization(org.id, { 
      name: formData.get('name') as string,
      numberOfPersons: Number(formData.get('numberOfPersons'))
    });
  };

  return (
    <div className="bg-white shadow-sm rounded-xl p-4 sm:p-6 border border-gray-100">
      <div className="flex items-center space-x-3 mb-6">
        <Users className="h-6 w-6 text-menioo-green" />
        <h2 className="text-xl font-display font-bold text-menioo-navy">
          Organization Settings
        </h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-menioo-navy mb-1">
              Organization Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={org.name}
              disabled={!isOwner && !isAdmin}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm disabled:bg-gray-50 disabled:text-gray-500"
            />
            {!isOwner && !isAdmin && (
              <p className="mt-1 text-xs sm:text-sm text-gray-500">
                Only owners can modify the organization name
              </p>
            )}
          </div>

          <div>
            <label htmlFor="numberOfPersons" className="block text-sm font-medium text-menioo-navy mb-1">
              Number of Persons
            </label>
            <input
              type="number"
              name="numberOfPersons"
              id="numberOfPersons"
              min="1"
              max="20"
              defaultValue={org.numberOfPersons || 1}
              disabled={!isOwner && !isAdmin}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm disabled:bg-gray-50 disabled:text-gray-500"
            />
            <p className="mt-1 text-xs sm:text-sm text-gray-500">
              {isOwner || isAdmin
                ? "This number will be used to adjust recipe portions"
                : "Only owners can modify the number of persons"}
            </p>
          </div>
        </div>

        {(isOwner || isAdmin) && (
          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full sm:w-auto bg-menioo-green text-white px-4 py-2 rounded-md hover:bg-menioo-green-dark transition-colors focus:outline-none focus:ring-2 focus:ring-menioo-green focus:ring-offset-2"
            >
              Update
            </button>
          </div>
        )}
      </form>
    </div>
  );
}