import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'lucide-react';
import { Recipe } from '../../types';

interface CategorySectionProps {
  category: string;
  color: string;
  items: Array<{
    ids: string[];
    name: string;
    quantity: number;
    unit: string;
    category: string;
    checked: boolean;
    recipeIds?: string[];
  }>;
  onToggleItem: (ids: string[]) => void;
  recipes: Recipe[];
}

export function CategorySection({ category, items, color, onToggleItem, recipes }: CategorySectionProps) {
  const { t } = useTranslation();

  // Get recipe names for an item
const getRecipeNames = (recipeIds: string[] = []) => {
  if (!recipeIds?.length) return '';
  const names = recipeIds
    .map(id => recipes.find(r => r.id === id)?.name)
    .filter(Boolean);
  return names.length > 0 ? names.join('\n') : '';
};

  // Format quantity and unit with proper translation
  const formatQuantityAndUnit = (quantity: number, unit: string) => {
    const translatedUnit = t(`units.${unit}`, { defaultValue: unit });
    return `${quantity} ${translatedUnit.toLowerCase()}`;
  };

  return (
    <div className="mb-6">
      {/* Category Header */}
      <div 
        className="bg-gray-100 px-4 py-2 font-medium text-gray-700 border-l-4"
        style={{ borderColor: color }}
      >
        {t(`categories.${category}`)}
      </div>
      
      {/* Items List */}
      <div className="bg-white shadow-sm rounded-b-lg divide-y divide-gray-100">
        {items.map((item) => {
          const itemKey = `${item.name}-${item.unit}-${item.ids[0]}`;
          const recipeInfo = getRecipeNames(item.recipeIds);
          
          return (
            <div 
              key={itemKey} 
              className="flex items-center px-4 py-2 hover:bg-gray-50 print:hover:bg-transparent group"
            >
              {/* Checkbox */}
              <button
                onClick={() => onToggleItem(item.ids)}
                className={`flex-shrink-0 w-4 h-4 rounded border transition-colors ${
                  item.checked
                    ? 'bg-menioo-orange border-menioo-orange'
                    : 'border-gray-300 group-hover:border-menioo-orange'
                }`}
              >
                {item.checked && <Check className="h-3 w-3 text-white" />}
              </button>

              {/* Item Content */}
              <div className={`flex-1 ml-3 min-w-0 ${
                item.checked ? 'text-gray-400 line-through' : ''
              }`}>
                {/* Name and Recipe Info */}
                <div className="min-w-0">
                  <div className="text-sm text-gray-900 truncate">
                    {item.name}
                  </div>
              {recipeInfo && (
  <div className="text-xs text-gray-400 whitespace-pre-line">
    {recipeInfo}
  </div>
)}

                </div>
              </div>

              {/* Quantity - Move to the right */}
              <div className="ml-4 text-sm text-gray-500 flex-shrink-0">
                {formatQuantityAndUnit(item.quantity, item.unit)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}