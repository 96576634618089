import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Plus, ShoppingBasket } from 'lucide-react';
import { Recipe } from '../../types';
import { RecipeSelectionModal } from './RecipeSelectionModal';
import { MealTypeModal } from './MealTypeModal';
import { Droppable } from '@hello-pangea/dnd';
import { useAuthStore } from '../../store/useAuthStore';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { addRecipeToMealPlanAndGrocery, addRecipeIngredientsManually } from '../../utils/mealPlanUtils';
import { MealEntryCard } from './MealEntryCard';

interface MealSectionCalendarProps {
  date: Date;
  recipes: Recipe[];
  mealEntries?: MealEntry[];
  onQuickAdd: (recipe: Recipe) => void;
  isAutomationEnabled: boolean;
  onOpenGroceryList: () => void;
  handleAddWeekToGroceryList: () => Promise<void>;
}

export function MealSectionCalendar({
  date,
  recipes = [],
  mealEntries = [],
  onQuickAdd,
  isAutomationEnabled,
  onOpenGroceryList,
  handleAddWeekToGroceryList
}: MealSectionCalendarProps) {
  const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);
  const [isMealTypeModalOpen, setIsMealTypeModalOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentMealPlan, updateMealPlan } = useMealPlanManagerStore();

  const dateStr = format(date, 'yyyy-MM-dd');
  const isToday = format(new Date(), 'yyyy-MM-dd') === dateStr;

  const dayMeals = mealEntries
    .filter((entry) => entry.date === dateStr)
    .map((entry) => ({
      ...entry,
      recipe: recipes.find((r) => r.id === entry.recipeId),
    }))
    .filter((meal) => meal.recipe);

  const handleSelectRecipe = (recipe: Recipe) => {
    setSelectedRecipe(recipe);
    setIsRecipeModalOpen(false);
    setIsMealTypeModalOpen(true);
  };

  const handleSelectMealType = async (mealType: string) => {
    if (!selectedRecipe || !currentUser?.defaultOrganizationId) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        selectedRecipe,
        dateStr,
        mealType,
        currentUser.id,
        currentUser.defaultOrganizationId
      );
      setIsMealTypeModalOpen(false);
      setSelectedRecipe(null);
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  // Add this new function inside MealSectionCalendar component
  const handleAddDayToGroceryList = async () => {
    if (!currentUser?.defaultOrganizationId) {
      console.error('No authenticated user or organization');
      return;
    }

    if (!currentMealPlan?.id) {
      console.error('No active meal plan');
      return;
    }

    try {
      // Filter meal entries for this specific date
      const dayMeals = currentMealPlan.mealEntries
        .filter(entry => entry.date === dateStr)
        .map(entry => ({
          ...entry,
          recipe: recipes.find(r => r.id === entry.recipeId)
        }))
        .filter(meal => meal.recipe && meal.recipe.id);

      if (dayMeals.length === 0) {
        console.log('No recipes found for this day');
        return;
      }

      // Add each recipe's ingredients to the grocery list
      for (const meal of dayMeals) {
        if (!meal.recipe) continue;
        
        await addRecipeIngredientsManually(
          meal.recipe,
          currentUser.defaultOrganizationId,
          currentMealPlan.id,
          meal.portions || meal.recipe.servings // Use meal portions or fallback to recipe servings
        );
      }

      // Open grocery list after adding items
      onOpenGroceryList();
    } catch (error) {
      console.error('Error adding day to grocery list:', error);
      // Here you could also add some UI feedback for the error
    }
  };

  const handleUpdateMealEntry = async (mealId: string, updates: any) => {
    if (!currentMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      const updatedEntries = currentMealPlan.mealEntries.map((entry) =>
        entry.id === mealId ? { ...entry, ...updates } : entry
      );

      await updateMealPlan(currentMealPlan.id, {
        mealEntries: updatedEntries,
      });
    } catch (error) {
      console.error('Error updating meal entry:', error);
    }
  };

  const handleRemoveMealEntry = async (mealId: string) => {
    if (!currentMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      const updatedEntries = currentMealPlan.mealEntries.filter((entry) => entry.id !== mealId);

      await updateMealPlan(currentMealPlan.id, {
        mealEntries: updatedEntries,
      });
    } catch (error) {
      console.error('Error removing meal entry:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      {/* Day Header */}
      <div
        className={`p-4 flex items-center justify-between ${
          isToday ? 'bg-menioo-green/10' : 'bg-gray-50'
        }`}
      >
        <div>
          <h3 className="font-medium text-menioo-navy">
            {format(date, 'EEEE', { locale: fr })}
          </h3>
          <p className="text-sm text-gray-500">
            {format(date, 'd MMM', { locale: fr })}
          </p>
        </div>
          <div className="flex justify-end items-center space-x-2">
            <button
              onClick={() => setIsRecipeModalOpen(true)}
              className="p-2 text-gray-500 hover:text-menioo-green hover:bg-menioo-green/10 rounded-full transition-colors"
            >
              <Plus className="h-5 w-5" />
            </button>
            <button
              onClick={handleAddDayToGroceryList}
              className="p-2 text-gray-500 hover:text-menioo-green hover:bg-menioo-green/10 rounded-full transition-colors"
            >
              <ShoppingBasket className="h-5 w-5" />
            </button>
        </div>
      </div>

      {/* Droppable Area */}
      <Droppable droppableId={dateStr}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`p-4 min-h-[100px] transition-colors ${
              snapshot.isDraggingOver ? 'bg-menioo-green/5' : ''
            }`}
          >
            <div className="space-y-2">
              {dayMeals.map((meal) => (
                <MealEntryCard
                  key={meal.id}
                  entry={meal}
                  onUpdate={(updates) => handleUpdateMealEntry(meal.id, updates)}
                  onRemove={() => handleRemoveMealEntry(meal.id)}
                  isArchived={false}
                  isAutomationEnabled={isAutomationEnabled}
                />
              ))}
              {dayMeals.length === 0 && (
                <div className="h-16 flex items-center justify-center border-2 border-dashed border-gray-200 rounded-lg text-gray-400">
                  <span className="text-sm">Glissez une recette ici</span>
                </div>
              )}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>

      {/* Recipe Selection Modal */}
      {isRecipeModalOpen && (
        <RecipeSelectionModal
          recipes={recipes}
          onClose={() => setIsRecipeModalOpen(false)}
          onSelectRecipe={handleSelectRecipe}
        />
      )}

      {/* Meal Type Modal */}
      {isMealTypeModalOpen && selectedRecipe && (
        <MealTypeModal
          recipe={selectedRecipe}
          isOpen={isMealTypeModalOpen}
          onClose={() => {
            setIsMealTypeModalOpen(false);
            setSelectedRecipe(null);
          }}
          onSelect={handleSelectMealType}
          onSkip={async () => {
            if (!selectedRecipe || !currentUser?.defaultOrganizationId) return;

            try {
              await addRecipeToMealPlanAndGrocery(
                selectedRecipe,
                dateStr,
                '', // Empty meal type
                currentUser.id,
                currentUser.defaultOrganizationId,
                selectedRecipe.servings
              );
              setIsMealTypeModalOpen(false);
              setSelectedRecipe(null);
            } catch (error) {
              console.error('Error adding recipe:', error);
            }
          }}
        />
      )}
    </div>
  );
}