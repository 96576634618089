import React from 'react';
import { Navigate, Outlet, useLocation, Link } from 'react-router-dom';
import { ChefHat, Users, Utensils } from 'lucide-react';

// Hardcoded admin credentials - in production, use proper authentication
const ADMIN_EMAIL = 'admin@menioo.com';
const ADMIN_PASSWORD = 'Admin123!';

export function AdminLayout() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(() => {
    return sessionStorage.getItem('adminAuthenticated') === 'true';
  });

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    if (email === ADMIN_EMAIL && password === ADMIN_PASSWORD) {
      sessionStorage.setItem('adminAuthenticated', 'true');
      setIsAuthenticated(true);
    } else {
      alert('Invalid credentials');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-menioo-cream flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <div className="flex justify-center">
              <div className="rounded-full bg-white p-3 shadow-md">
                <ChefHat className="h-12 w-12 text-menioo-green" />
              </div>
            </div>
            <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
              Admin Access
            </h2>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-menioo-navy">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-menioo-navy">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-menioo-green hover:bg-menioo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-menioo-green"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-menioo-cream">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center">
                <ChefHat className="h-8 w-8 text-menioo-green" />
                <span className="ml-2 text-xl font-display font-bold text-menioo-navy">
                  Menioo Admin
                </span>
              </div>

              {/* Navigation Links */}
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="/admin/ingredients"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                    location.pathname === '/admin/ingredients'
                      ? 'text-menioo-green border-b-2 border-menioo-green'
                      : 'text-gray-500 hover:text-menioo-navy'
                  }`}
                >
                  <Utensils className="h-4 w-4 mr-2" />
                  Ingredients
                </Link>
                <Link
                  to="/admin/organizations"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                    location.pathname.startsWith('/admin/organizations')
                      ? 'text-menioo-green border-b-2 border-menioo-green'
                      : 'text-gray-500 hover:text-menioo-navy'
                  }`}
                >
                  <Users className="h-4 w-4 mr-2" />
                  Organizations
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </div>
    </div>
  );
}