import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Organization } from '../../types';
import { OrganizationSettings } from '../organization/OrganizationSettings';
import { MembersList } from '../organization/MembersList';
import { ChefHat } from 'lucide-react';

export function AdminOrganizationView() {
  const { id } = useParams();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrganization = async () => {
      if (!id) return;

      try {
        const orgDoc = await getDoc(doc(db, 'organizations', id));
        if (!orgDoc.exists()) {
          setError('Organization not found');
          return;
        }

        setOrganization({ id: orgDoc.id, ...orgDoc.data() } as Organization);
      } catch (error) {
        console.error('Error fetching organization:', error);
        setError('Failed to load organization');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganization();
  }, [id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-menioo-green"></div>
      </div>
    );
  }

  if (error || !organization) {
    return (
      <div className="min-h-[60vh] flex flex-col items-center justify-center p-4">
        <ChefHat className="h-16 w-16 text-menioo-green mb-4" />
        <p className="text-menioo-navy font-medium text-center">{error || 'Organization not found'}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="grid gap-6">
        <OrganizationSettings organization={organization} />
        <MembersList organization={organization} />
      </div>
    </div>
  );
}