import React from 'react';
import { Recipe } from '../../types';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface RecipeViewProps {
  items: Array<{
    ids: string[];
    name: string;
    quantity: number;
    unit: string;
    category: string;
    checked: boolean;
    recipeIds?: string[];
  }>;
  recipes: Recipe[];
  onToggleItem: (ids: string[]) => void;
}

export function RecipeView({ items = [], recipes = [], onToggleItem }: RecipeViewProps) {
  const { t } = useTranslation();

  // Group items by recipe
  const groupedByRecipe = React.useMemo(() => {
    const grouped: Record<string, typeof items> = {
      noRecipe: []
    };

    items.forEach(item => {
      if (!item.recipeIds || item.recipeIds.length === 0) {
        // Items without recipe go to noRecipe group
        grouped.noRecipe.push(item);
      } else {
        // Add item to each recipe group it belongs to
        item.recipeIds.forEach(recipeId => {
          if (!recipeId) return;
          if (!grouped[recipeId]) {
            grouped[recipeId] = [];
          }
          grouped[recipeId].push(item);
        });
      }
    });

    return grouped;
  }, [items]);

  // Format quantity and unit with proper translation
  const formatQuantityAndUnit = (quantity: number, unit: string) => {
    if (!unit) return `${quantity}`;
    const translatedUnit = t(`units.${unit}`, { defaultValue: unit });
    return `${quantity} ${translatedUnit.toLowerCase()}`;
  };

  // Generate unique key for list items
  const getItemKey = (item: RecipeViewProps['items'][0]) => {
    if (!item || !Array.isArray(item.ids) || item.ids.length === 0) {
      return crypto.randomUUID();
    }
    return item.ids[0];
  };

  return (
    <div className="divide-y divide-gray-100">
      {/* Items without recipes */}
      {groupedByRecipe.noRecipe.length > 0 && (
        <div className="p-6">
          <h3 className="text-lg font-medium text-menioo-navy mb-4">
            Sans recette
          </h3>
          <ul className="space-y-3">
            {groupedByRecipe.noRecipe.map((item) => {
              if (!item || !Array.isArray(item.ids)) return null;
              const key = getItemKey(item);

              return (
                <li key={key} className="flex items-start">
                  <button
                    onClick={() => onToggleItem(item.ids)}
                    className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                      item.checked
                        ? 'bg-menioo-green border-menioo-green'
                        : 'border-gray-300 hover:border-menioo-green'
                    }`}
                  >
                    {item.checked && <Check className="h-3 w-3 text-white" />}
                  </button>
                  <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                    <span className="font-medium">
                      {formatQuantityAndUnit(item.quantity, item.unit)}
                    </span>{' '}
                    {item.name}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {/* Recipe-grouped items */}
      {Object.entries(groupedByRecipe)
        .filter(([key]) => key !== 'noRecipe')
        .map(([recipeId, items]) => {
          const recipe = recipes.find(r => r.id === recipeId);
          if (!recipe) return null;

          return (
            <div key={recipeId} className="p-6">
              <h3 className="text-lg font-medium text-menioo-navy mb-4">
                {recipe.name}
              </h3>
              <ul className="space-y-3">
                {items.map((item) => {
                  if (!item || !Array.isArray(item.ids)) return null;
                  const key = getItemKey(item);

                  return (
                    <li key={key} className="flex items-start">
                      <button
                        onClick={() => onToggleItem(item.ids)}
                        className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                          item.checked
                            ? 'bg-menioo-green border-menioo-green'
                            : 'border-gray-300 hover:border-menioo-green'
                        }`}
                      >
                        {item.checked && <Check className="h-3 w-3 text-white" />}
                      </button>
                      <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                        <span className="font-medium">
                          {formatQuantityAndUnit(item.quantity, item.unit)}
                        </span>{' '}
                        {item.name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
    </div>
  );
}