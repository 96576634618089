import React, { useState } from 'react';
import { Users, UserPlus, Trash2 } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { UserRole, Organization } from '../../types';

interface MembersListProps {
  organization?: Organization;
}

export function MembersList({ organization }: MembersListProps) {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState<UserRole>('contributor');

  const currentOrg = useAuthStore((state) => state.currentOrganization);
  const currentUser = useAuthStore((state) => state.currentUser);
  const addUserToOrganization = useAuthStore((state) => state.addUserToOrganization);
  const removeUserFromOrganization = useAuthStore((state) => state.removeUserFromOrganization);
  const updateUserRole = useAuthStore((state) => state.updateUserRole);

  const org = organization || currentOrg;
  if (!org) return null;

  const isOwner = currentUser?.organizations[org.id]?.role === 'owner';
  const isAdmin = location.pathname.startsWith('/admin/organizations/');

  const handleAddUser = (e: React.FormEvent) => {
    e.preventDefault();
    addUserToOrganization(newUserEmail, newUserRole);
    setNewUserEmail('');
    setNewUserRole('contributor');
  };

  const translateRole = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Owner';
      case 'contributor':
        return 'Contributor';
      case 'viewer':
        return 'Viewer';
      default:
        return role;
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl p-4 sm:p-6 border border-gray-100">
      <div className="flex flex-col gap-4 mb-6">
        <div className="flex items-center space-x-3">
          <Users className="h-6 w-6 text-menioo-green" />
          <h2 className="text-xl font-display font-bold text-menioo-navy">
            Members
          </h2>
        </div>

        {(isOwner || isAdmin) && (
          <form onSubmit={handleAddUser} className="flex flex-col gap-2">
            <input
              type="email"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              placeholder="Email address"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              required
            />
            <div className="flex flex-col sm:flex-row gap-2">
              <select
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value as UserRole)}
                className="w-full sm:w-auto rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              >
                <option value="owner">Owner</option>
                <option value="contributor">Contributor</option>
                <option value="viewer">Viewer</option>
              </select>
              <button
                type="submit"
                className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-menioo-green hover:bg-menioo-green-dark transition-colors"
              >
                <UserPlus className="h-4 w-4 mr-2" />
                Add
              </button>
            </div>
          </form>
        )}
      </div>

      <div className="overflow-x-auto -mx-4 sm:mx-0">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden rounded-lg border border-gray-100">
            <div className="min-w-full divide-y divide-gray-200">
              {/* Header */}
              <div className="bg-menioo-cream">
                <div className="grid grid-cols-12 gap-2 px-4 py-3">
                  <div className="col-span-7 sm:col-span-6 text-xs font-medium text-menioo-navy uppercase">
                    User
                  </div>
                  <div className="col-span-3 sm:col-span-4 text-xs font-medium text-menioo-navy uppercase">
                    Role
                  </div>
                  {(isOwner || isAdmin) && (
                    <div className="col-span-2 text-xs font-medium text-menioo-navy uppercase text-right">
                      Actions
                    </div>
                  )}
                </div>
              </div>

              {/* Body */}
              <div className="bg-white divide-y divide-gray-100">
                {org.members.map((user) => (
                  <div 
                    key={`${user.id}-${user.email}`} 
                    className="grid grid-cols-12 gap-2 px-4 py-4 group hover:bg-menioo-cream/30 transition-colors items-center"
                  >
                    {/* User Info */}
                    <div className="col-span-7 sm:col-span-6">
                      <div className="flex items-center space-x-2 sm:space-x-3">
                        <div className="flex-shrink-0 h-8 w-8 sm:h-10 sm:w-10">
                          <div className="h-full w-full rounded-full bg-menioo-green/10 flex items-center justify-center">
                            <Users className="h-4 w-4 sm:h-5 sm:w-5 text-menioo-green" />
                          </div>
                        </div>
                        <div className="min-w-0">
                          <div className="text-sm font-medium text-menioo-navy truncate">
                            {user.name}
                          </div>
                          <div className="text-xs text-gray-500 truncate">
                            {user.email}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Role */}
                    <div className="col-span-3 sm:col-span-4">
                      {(isOwner || isAdmin) && user.id !== currentUser?.id ? (
                        <select
                          value={user.role}
                          onChange={(e) => updateUserRole(user.id, e.target.value as UserRole)}
                          className="w-full text-xs sm:text-sm rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
                        >
                          <option value="owner">Owner</option>
                          <option value="contributor">Contributor</option>
                          <option value="viewer">Viewer</option>
                        </select>
                      ) : (
                        <span className="inline-flex text-xs leading-5 font-semibold rounded-full bg-menioo-green/10 text-menioo-green px-2 py-1">
                          {translateRole(user.role)}
                        </span>
                      )}
                    </div>

                    {/* Actions */}
                    {(isOwner || isAdmin) && (
                      <div className="col-span-2 flex justify-end">
                        {user.id !== currentUser?.id && (
                          <button
                            onClick={() => removeUserFromOrganization(user.id)}
                            className="text-red-500 hover:text-red-600 transition-colors p-1"
                            title="Remove member"
                          >
                            <Trash2 className="h-4 w-4 sm:h-5 sm:w-5" />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}